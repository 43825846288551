import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';
import { authorizedFetch, validateIPAddress } from '../../utils';
import { addError, addSuccess } from '../../toasts/toasts.actions';

export const receiveTrafficPartner = createAction('receiveTrafficPartner');
export const setTrafficPartnerIsBusy = createAction('setTrafficPartnerIsBusy');
export const modifyTrafficPartner = createAction('modifyTrafficPartner');
export const closeTrafficPartner = createAction('closeTrafficPartner');

export const TrafficPartnerModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.TRAFFIC_PARTNER
});

export const getItem = getItemInternal(
  TrafficPartnerModel,
  receiveTrafficPartner,
  setTrafficPartnerIsBusy,
  item => {
    if (Array.isArray(item.ipAllowRanges)) {
      item.ipAllowRanges = Object.entries(item.ipAllowRanges).map(
        ([key, value]) => {
          return { id: value.id, key: value.start, value: value.end };
        }
      );
    }
    if (!item.postbackItem) {
      Object.assign(item, {
        postbackItem: {}
      });
    }
  }
);
export const saveItem = saveItemInternal(
  modifyTrafficPartner,
  item => {
    if (item.postbackItem?.sources.length === 0) {
      return 'Please add a source to activate this postback.';
    }
    if (item?.postbackItem?.eventTypes.length === 0) {
      return 'Please select at least one event type';
    }
    if (item.partnerType === 'offersyndication' && !item.businessUnitId) {
      return 'Business Unit is required when Partner Type is Offer Syndication.';
    }
    if (Array.isArray(item.ipAllowRanges)) {
      // validate the IP Addresses
      const ipItems = item.ipAllowRanges.filter(
        f => f.key !== '' || f.value !== ''
      );
      const ipsValidation = validateIPs(ipItems);
      if (ipsValidation !== true) {
        return ipsValidation;
      }
    }
  },
  itemCopy => {
    if (itemCopy.postbackItem) {
      const postbackItem = itemCopy.postbackItem;
      if (
        postbackItem.bodyTemplate === '' ||
        (postbackItem.bodyTemplate &&
          postbackItem.bodyTemplate.replace(/\s/g, '') === '{}')
      ) {
        delete postbackItem.bodyTemplate;
      }

      if (postbackItem.headers && postbackItem.headers.length > 0) {
        postbackItem.headers = postbackItem.headers.filter(h => h.key !== '');
      }
    }

    itemCopy.postbackConfigurations = [
      ...itemCopy.postbackConfigurations,
      itemCopy.postbackItem
    ];

    if (Array.isArray(itemCopy.ipAllowRanges)) {
      const ipItems = itemCopy.ipAllowRanges.filter(
        f => f.key !== '' || f.value !== ''
      );
      itemCopy.ipAllowRanges = Object.entries(ipItems).map(([key, value]) => {
        return {
          id: value.id,
          start: value.key,
          end: value.value
        };
      });
    }
  },
  async (itemCopy, { id, version }, dispatch, modifyItemAction) => {
    if (window.location.href.includes('/advanced/new-')) {
      const url = `/trafficpartner/${id}/advanced/${itemCopy.postbackItem.id}`;
      var updatedUrl = window.location.href.replace(
        /new-[^/]+/,
        url.split('/advanced/')[1]
      );
      window.history.replaceState(null, '', updatedUrl);
    }
  }
);

export const postbackActivation = (
  id,
  activate,
  setIsLoading,
  handleChange,
  postbackItem
) => async dispatch => {
  setIsLoading(true);
  const prefix = activate ? 'activat' : 'deactivat';
  try {
    await authorizedFetch(
      `/api/trafficpartner/${id}/postback/${prefix}e`,
      'POST'
    );
    dispatch(addSuccess(`Postback ${prefix}ed successfully`));
    handleChange({ ...postbackItem, active: activate });
  } catch (e) {
    dispatch(addError(`Postback ${prefix}ion failed`, e.Message));
  }

  setIsLoading(false);
};

export const sendPostbackTest = (
  id,
  postbackRequest,
  setResponse,
  setIsLoading
) => async dispatch => {
  setIsLoading(true);
  try {
    const result = await authorizedFetch(
      `/api/trafficpartner/${id}/postback/test`,
      'POST',
      postbackRequest
    );

    setResponse(result);
  } catch (e) {
    dispatch(addError('Postback test failed', e.Message));
  }

  setIsLoading(false);
};

const validateIPs = ipAllowRanges => {
  if (Array.isArray(ipAllowRanges)) {
    for (let ip of ipAllowRanges) {
      const ipKey = ip.key;
      const ipValue = ip.value;

      // If key is defined but value is not
      if (ipKey !== '' && ipValue === '') {
        return 'Please add an IP address for the end range. Put same value as start range if only 1 IP exists.';
      }
      // If value is defined but key is not
      if (ipValue !== '' && ipKey === '') {
        return 'Please add an IP address for the start range.';
      }
      // Validate IP addresses
      if (!validateIPAddress(ipKey) || !validateIPAddress(ipValue)) {
        return 'Please enter a valid IP address';
      }
    }
    return true;
  }
};
